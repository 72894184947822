import {
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import * as React from "react";
import HttpCheckoutRepository from "../api/request/Checkout/Checkout.service";
import { GetLifeTrackerContext } from "./GetLifeTracker.context";
import { useContext } from "react";
import { GetlifeContext } from "./GetLifeContext.context";
import { I18nContext } from "./i18n.context";
import { numberFormatPayment } from "../utils/Utils";
import { getCookie } from "../hooks/getCookie";

interface ContextProps {
  handleSubmit: () => void;
}

export const StripeContext = React.createContext({} as ContextProps);

export const StripeProvider = ({
  handleIsLoading,
  setupIntent,
  children,
  leadId,
  token,
  checkout,
  navigate
}: any) => {
  const {
    setNotification,
    nodeElementAlert,
    utm_campaign,
    utm_source,
    utm_medium,
    utm_content,
    utm_term,
    utm_test,
    gclid,
    msclkid,
    fbclid,
    getEntryPage,
    getReferrerUrl,
    getABTestVersion
  } = useContext(GetlifeContext);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const { handleTrackerQuestion } = React.useContext(GetLifeTrackerContext);
  const checkoutRepository = new HttpCheckoutRepository(leadId, token);

  const stripe = useStripe();
  const elements = useElements();

  const stripeErrors = [
    "card_declined",
    "expired_card",
    "incorrect_cvc",
    "processing_error",
  ];

  const message = (error: any) => {
    if (error.decline_code === "insufficient_funds") {
      return translate(`card.stripe.error.${error.decline_code}`);
    } else if (stripeErrors.includes(error.code)) {
      return translate(`card.stripe.error.${error.code}`);
    } else {
      return translate("card.stripe.error.generic");
    }
  };

  const fetchSavePayment = async (paymentMethodData: any) => {
    try {
      const res = await checkoutRepository.savePaymentMethod(paymentMethodData);
      if (res !== "OK") {
        throw new Error();
      }
      
      const paymentEvent = "paymentMethodSet";

      const paymentData = {
        page: window.location.href,
        leadId: leadId,
        calculatedCapital: parseFloat(checkout.capital),
        coverage: checkout.coverage === "premium" ? "FIPA" : "F",
        intent: checkout.intention,
        calculatedPremium: parseFloat(checkout.regularPrice),
        paymentMethod: "card",
        utm_source: utm_source,
        utm_campaign: utm_campaign,
        utm_medium: utm_medium,
        utm_content: utm_content,
        utm_term: utm_term,
        utm_test: utm_test,
        gclid: gclid,
        msclkid: msclkid,
        fbclid: fbclid,
        platform: "App",
        discountedPremium: parseFloat(numberFormatPayment(checkout.priceToPay)),
        paymentPeriod: checkout.period ? checkout.period.toLowerCase() : "monthly",
        entryPage: getEntryPage(),
        referrerUrl: getReferrerUrl(),
        ABTestVersion: getABTestVersion()
      }

      handleTrackerQuestion({
        type: "GA",
        data: {
          event: paymentEvent,
          ...paymentData
        },
      });
      handleTrackerQuestion({
        type: "FS",
        event: paymentEvent,
        data: paymentData,
      });
      
      navigate();
    } catch (error) {
      setNotification({
        message: translate("card.stripe.error.generic"),
        type: "error",
        time: 5,
      });
      nodeElementAlert.current.handleShowPortal();
      handleIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.

    handleIsLoading(true);

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }
    try {
      const result = await stripe.confirmCardSetup(setupIntent.clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement)!,
          billing_details: {
            name: setupIntent.name + " " + setupIntent.lastName,
          },
        },
      });

      if (result.error) {
        throw result.error;
      }
      const paymentMethodData = {
        providerId: result.setupIntent.payment_method,
        setupIntent: result.setupIntent.id,
        leadId: leadId,
      };
      await fetchSavePayment(paymentMethodData);
      handleIsLoading(false);
      window.scrollTo(0, 0);
    } catch (error: any) {
      setNotification({
        message: message(error),
        type: "error",
        time: 5,
        positionY: "bottom",
      });
      nodeElementAlert.current.handleShowPortal();
      handleIsLoading(false);
    }
  };
  const values = {
    handleSubmit,
  };

  return (
    <StripeContext.Provider value={values}>{children}</StripeContext.Provider>
  );
};
