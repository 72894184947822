import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Animation from "./../../../../../components/Components/lotties/waitingSignature.json";
import Lottie from "lottie-react";
import FadeIn from "react-fade-in";
import secure from "./../../../../../assets/img/secure.svg";
import "./../css/waitingSignature.scss";
import { GetlifeContext } from "../../../../../context/GetLifeContext.context";
import { I18nContext } from "../../../../../context/i18n.context";
import { ButtonDS } from "get-life-storybook-ts";
import HttpIntegrationsRepository from "../../../../../api/request/Integrations/Integrations.service";
import { DOMAIN } from "../../../../../constants/Global";
import Star from "../../../../../assets/img/truspilotStar.svg";

export default function WaitingSignature() {
  const location = useLocation();
  const { token, leadId } = useContext(GetlifeContext);

  const [isSigned, setIsSigned] = useState(true);

  const integrationsRepository = new HttpIntegrationsRepository(leadId, token);

  const rateUs = () => {
    window.open(translate("trustpilot.link"), "_blank");
  };

  const {
    state: { translate },
  } = useContext(I18nContext);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const TrustPilotStars = () => {
    // create empty array len 5 and iterate over it
    const stars = Array.from(Array(5)).map( ( _, index) => {
      return (
        <div key={index} className={"p-0.5 w-5 inline-block mr-0.5"} style={{ backgroundColor: "#00B67A" }}>
          <img src={Star} className="w-full" alt="Trustpilot" />
        </div>
      )
    })
  
    return <div>{ stars }</div>
  }

  const inmediateSigned =
    new URLSearchParams(location.search).get("signed") === "1";
  const superTitle = inmediateSigned
    ? translate("waitingSignature.superTitle1")
    : translate("waitingSignature.superTitle2");
  const titleText = inmediateSigned
    ? translate("waitingSignature.titleText1")
    : translate("waitingSignature.titleText2");
  const subtitleText = inmediateSigned
    ? translate("waitingSignature.subtitleText1")
    : translate("waitingSignature.subtitleText2");
  const paragraphText = inmediateSigned
    ? ""
    : translate("waitingSignature.paragraphText");

    const getTokenCello = async () => {
      const token = await integrationsRepository.getTokenCello(leadId);

      return token.token;
    }

    useEffect(async () => {
      
      (async () => {
        if (inmediateSigned && (DOMAIN === "es" || DOMAIN === "wl_es")) {

          const token = await getTokenCello();
        
          window.cello = window.cello || { cmd: [] };
      
          window.cello.cmd.push(async function (cello) {
            try {
              await cello.boot({
                productId: process.env.REACT_APP_CELLO_PRODUCT_ID,
                token: token,
                language: "es",
              });
            } catch (error) {
              console.error("Failed to boot cello:", error);
            }
          });
        }
      })();
    }, [])

  return (

    <div className={"waitingSignature-outerContainer"}>
      <FadeIn>
        <div className={"waitingSignature-container"}>
          <section className="waitingSignature-topContainer">
            <div className="waitingSignature-topContent">
              <Lottie
                className="waitingSignature-animation"
                animationData={Animation}
                loop={false}
              />
              <p className="waitingSignature-congrats">
                {translate("waitingSignature.congratulations")}
              </p>
              <p className="waitingSignature-congratsBold">{superTitle}</p>
              <p style={{ paddingTop: "29px" }} className="waitingSignature-p">
                {titleText}
              </p>
              <p className="waitingSignature-p">{subtitleText}</p>

              {!isSigned && (
                <p className="waitingSignature-signedError">{paragraphText}</p>
              )}
            </div>
          </section>

          <section className="waitingSignature-botomContainer">
            <article className="waitingSignature-boxContainer">
              <div className="waitingSignature-boxContent">
                  <>
                    <p className="waitingSignature-box-title">
                      <p>{translate("waitingSignature.experience")} </p>
                      <div className="waitingSignature-starsContent">
                        {TrustPilotStars()}
                      </div>
                    </p>
                    <p className="waitingSignature-box-title">
                      {translate("waitingSignature.comment")}
                    </p>
                    <p className="waitingSignature-trusPilotLabel">
                      {translate("waitingSignature.trustpilot")}
                    </p>
                    <div className="waitingSignature-starBox">
                      <div className="waitingSignature-TruststarContainer">
                        <p className="waitingSignature-points">4.6</p>
                        <div className="waitingSignature-starsContent">
                        {TrustPilotStars()}
                        </div>
                      </div>
                      <div className="waitingSignature-starButtonContainer">
                        <ButtonDS
                          onClick={rateUs}
                          label={translate("waitingSignature.btnComment")}
                        />
                      </div>
                    </div>
                  </>
              </div>
            </article>
          </section>
          <div className="waitingSignature-footerContainer">
            <img src={secure} alt="aplicación segura" />
            <p className="waitingSignature-footer">
              {translate("paymentFooter.text")}
            </p>
          </div>
        </div>
      </FadeIn>
    </div>
  );
}
