
import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../context/i18n.context";
import HttpIdCardRepository from "../../api/request/IdCard/IdCard.service";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import { useNavigate } from "react-router";

const IdCardBiometryController = () => {

    const navigate = useNavigate();

    const [neededDocuments, setNeededDocuments] = useState<any>();
    const [documentSelected, setDocumentSelected] = useState<string>("");
    const [isDocumentSelected, setIsDocumentSelected] = useState<boolean>(false);
    const [urlBiometry, setUrlBiometry] = useState<string>("");
    const [checkedBiometry, setCheckedBiometry] = useState<boolean>(false);

    const {
        leadId,
        setLoading,
        token
    } = useContext(GetlifeContext);

    const {
        state: { translate },
    } = useContext(I18nContext);

    const idCardRepository = new HttpIdCardRepository(leadId, token);

    const getDocuments = async () => {
        setLoading(true);
        await idCardRepository.getDocumentsBiometry().then((response) => {
            let result: any[] = [];
            response.availableDocuments.forEach((item: string) => {
                let object = {
                    label: translate(`idCard.biometry.${item}`),
                    value: item,
                    icon: item === "PA" ? "PassportIcon" : "IdCardIcon"
                }
                result.push(object);
            })
            setNeededDocuments(result);
            setLoading(false);
        })
    };

    useEffect(() => {
        if (checkedBiometry) {
            getDocuments();
        } else {
            getStatusBiometry();
        }
    }, [checkedBiometry]);

    const selectDocument = () => {
        setLoading(true);
        setIsDocumentSelected(true);
        idCardRepository.getUrlBiometry(documentSelected).then((response) => {
            setUrlBiometry(response.externalUrl);
            setLoading(false);
        });
    }

    const getStatusBiometry = () => {
        idCardRepository.getStatusBiometry().then((response) => {
            if (response.status === "Finished") {
                idCardRepository.getValidateBiometry().then(() => {
                    navigate("/payment/4");
                }).catch((e) => {
                    navigate("/pendingvalidation");
                })
            } else {
                setCheckedBiometry(true);
            }
        }).catch((e) => {
            setCheckedBiometry(true);
        });
    }

    useEffect(() => {
        const getStatusBiometryInterval = setInterval(
          () => getStatusBiometry(),
          3000
        );
        return () => clearInterval(getStatusBiometryInterval);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);


    return {
        documentSelected,
        isDocumentSelected,
        neededDocuments,
        selectDocument,
        setDocumentSelected,
        translate,
        urlBiometry
    }

}

export default IdCardBiometryController;
