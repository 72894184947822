/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from "react";
import { useLocation } from "react-router";
import FormBuilder from "../../../components/Components/FormBuilder/FormBuilder";
import queryString from "query-string";
import "../css/questions.scss";
import { handleHubspot } from "../../../utils/HandleHubspot";
import { Dialog, DialogContent, DialogTitle, Slide } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { GetlifeContext } from "../../../context/GetLifeContext.context";
import { I18nContext } from "../../../context/i18n.context";
import HttpLeadRepository from "../../../api/request/Lead/Lead.service";
import FormButton from "../../../components/Components/FormBuilder/FormButtons";
import { FormBuilderProvider } from "../../../context/FormBuilder.context";
import { StatusContext } from "../../../context/StatusContext";
import MaxCapitalModal from "../../../components/MaxCapitalModal/MaxCapitalModal";
import { DOMAIN } from "../../../constants/Global";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CalculatorQuestions = (props) => {
  const { init } = useContext(GetlifeContext);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const {getStatus} = useContext(StatusContext);

  const { downgraded, upgraded } = props.params.question;
  const [chat, setchat] = useState(false);

  const [downgradedOpen, setDowngradedOpen] = useState(false);
  const [upGradedOpen, setUpGradedOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const leadRepository = new HttpLeadRepository(props.leadId, props.token);
  const Location = useLocation();
  const route = Location.pathname;
  const isOneChoinceQuestion =
    props.params.question.type === "radio" &&
    props.params.question.componentName &&
    route === "/stepChallenger/4";

  const handleChat = () => setchat(!chat);

  const handleDowngradedClose = () => {
    setDowngradedOpen(false);
  };

  const handleValues = (e) => {
    let filterValue = [];
    if (
      props.params.question.type === "select_multiple" ||
      props.params.question.type === "checkbox" ||
      props.params.question.type === "checkbox_optional" ||
      props.params.question.type === "checkbox_mandatory"
    ) {
      filterValue = [];
      for (let i = 0; i < e.length; i++) {
        filterValue.push(e[i]);
      }
      if (filterValue.includes("none")) {
        filterValue = filterValue.filter((v) => v !== 'none');
      }
      if (
        filterValue.length === 0 &&
        props.params.question.type === "checkbox_optional"
      ) {
        filterValue.push("none");
      }
      props.handleOnChange({ ...props.params, answer: filterValue.toString() });
    } else {
      const value = e ? e : "";
      props.handleOnChange({ ...props.params, answer: value });
    }
  };

  useEffect(() => {
    handleHubspot();
    const query = queryString.parse(window.location.search);

    if (props.token === false && props.leadId === false) {
      if (Object.keys(query).length === 0) {
        if (localStorage.getItem("lead")) {
          const lead = localStorage.getItem("lead");

          props.handleLeadId(lead);
        } else {
          init("calculator_question_not_token_not_lead");
        }
      } else {
        props.handleLeadId(query.lead);
      }
    }
  }, []);

  useEffect(() => {
    if (!props.token && props.leadId) {
      leadRepository.getToken(props.leadId).then((res) => {
        const { token } = res;

        if (!token) {
          init("calculator_question_not_token_lead");
        } else {
          props.handleToken(token || "");
        }
      });
    }
  }, [props.leadId]);

  useEffect(() => {
    if (props.token) {
      getStatus();
      props.refreshNextQuestion();
    }
  }, [props.token]);

  useEffect(() => {
    if (downgraded === 1 || downgraded === "1") {
      setDowngradedOpen(true);
    }
    if (upgraded) setUpGradedOpen(true);
  }, [downgraded, upgraded]);

  useEffect(() => {
    if (
      selectedValue !== null &&
      props.params.question.type === "radio" &&
      props.params.question.componentName
    ) {
      props.nextQuestion();
      setSelectedValue(null);
    }
  }, [selectedValue]);

  useEffect(() => {
    props.handleSelectorDisplayed(isDisplayed);
  }, [isDisplayed, setIsDisplayed]);
  const isVelogicaWidget = props.params.question.componentName === "external_underwriting";
  return (
    <div className={"questions-outerContainer"}>
      <Dialog
        fullWidth={false}
        maxWidth={"lg"}
        open={downgradedOpen}
        onClose={handleDowngradedClose}
        TransitionComponent={Transition}
      >
        <DialogTitle
          disableTypography
          className={"questions-downgraded-dialog-close"}
        >
          <IconButton onClick={handleDowngradedClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className={"questions-downgraded-dialog-content"}>
          <p>
            Debido a los datos que nos has facilitado, no podemos ofrecerte una
            garantía de <br />
            <span>Invalidez Permanente Absoluta</span>.
          </p>
          <p>
            A partir de este punto te vamos a preguntar lo necesario para darte
            un precio para la garantía de
            <br /> <span>Fallecimiento</span>
          </p>
        </DialogContent>
      </Dialog>

      {props.params.question !== null &&
        props.params.question !== undefined &&
        props.showCallMe === false &&
        props.isLoading === false && (
          <FormBuilderProvider
            answer={props.params.answer}
            question={props.params.question}
          >
            <div className={"questions-container"}>
              <div className={`questions-innerContainer ${DOMAIN === "it" ? "questions-innerContainer-it" : ""}`}>
                {props.params.question.code !== 0 && (
                  <FormBuilder
                    answer={props.params.answer}
                    handleChange={handleChat}
                    handleSubmit={props.nextQuestion}
                    handleValues={handleValues}
                    isLoading={props.isLoading}
                    question={props.params.question}
                    chat={chat}
                    inputWidth={"340px"}
                    callback={(e) => {
                      setSelectedValue(e);
                      handleValues(e);
                    }}
                    isOneChoinceQuestionRoute={route === "/stepChallenger/4"}
                    setIsDisplayed={setIsDisplayed}
                    isDisplayed={isDisplayed}
                    setValidateQuestionDateInput={() => ({})}
                  />
                )}
              </div>
            </div>
            {
              !isVelogicaWidget ?
                <FormButton
                  showButton={true}
                  isLoading={props.isLoading}
                  isOneChoinceQuestion={isOneChoinceQuestion}
                  componentName={props.params.question.componentName}
                  translate={translate}
                  handleSubmit={props.nextQuestion}
                  question={props.params.question}
                  answer={props.params.answer}
                  goBack={props.goBack}
                  questionStack={props.questionStack}
                  isSticky={false}
                />
              : null
            }
          </FormBuilderProvider>
        )}

      <MaxCapitalModal capital={props.params.question.newCapital}/>
    </div>
  );
};

export default CalculatorQuestions;
