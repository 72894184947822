import { useContext, useEffect, useState } from "react";
import apiRegisterHubspot from "../../api/request/apiRegisterHubspot";
import HttpLeadRepository from "../../api/request/Lead/Lead.service";
import HttpPaymentRepository from "../../api/request/Payment/Payment.service";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import { useQuery } from "../../context/GetLifeCalculator.context";
import { useNavigate } from "react-router";
import { GetLifeTrackerContext } from "../../context/GetLifeTracker.context";
import { I18nContext } from "../../context/i18n.context";
import { GlobalIcon, useDevice } from "get-life-storybook-ts";
import { NavbarStickyContent, NavbarStikyButton } from "./Rastreator";

const RastreatorController = () => {
  let query = useQuery();
  const navigate = useNavigate();
  const token = query.get("TOKEN") || query.get("token");
  const phoneUrl = query.get("PHONE") || query.get("phone");
  const birthdateUrl = query.get("BIRTHDATE") || query.get("birthdate");
  const [dataAggregator, setDataAggregator] = useState<any>();
  const [initialValues, setInitialValues] = useState<any>({
    email: "",
    phone: phoneUrl && phoneUrl !== "-999" ? phoneUrl : "",
    conditions: "",
    conditions2: "",
  });
  const [showComponent, setShowComponent] = useState(false);

  const {
    setToken,
    setLeadId,
    setUserStatus,
    init,
    setNavbarStickyInfo,
    setNotification,
    nodeElementAlert,
    utm_campaign,
    utm_source,
    utm_medium,
    utm_content,
    utm_term,
    utm_test,
    gclid,
    msclkid,
    fbclid,
    getEntryPage,
    getReferrerUrl,
    getABTestVersion
  } = useContext(GetlifeContext);

  const leadRepository = new HttpLeadRepository();
  const paymentRepository = new HttpPaymentRepository();

  const { isMobile } = useDevice();

  const {
    state: { translate },
  } = useContext(I18nContext);

  const { handleTrackerQuestion, identifyFS } = useContext(GetLifeTrackerContext);

  const scrollToDiv = () => {
    const targetDiv = document.getElementById("formC");
    targetDiv?.scrollIntoView({ behavior: "smooth" });
    nodeElementAlert.current.handleClosePortal();
    setNavbarStickyInfo({
      show: false,
    });
  };

  const handleScroll = () => {
    const currentPosition = window.scrollY;
    if (currentPosition > 900) {
      setShowComponent(true);
    } else {
      nodeElementAlert.current.handleClosePortal();
      setShowComponent(false);
    }
  };

  useEffect(() => {
    paymentRepository.getBestPrice(token!).then((response) => {
      const data = response;
      setInitialValues({
        ...initialValues,
        email: data.email ? data.email : initialValues.email,
        phone: (() => {
          if(data.phone) return data.phone;
          if(initialValues.phone && initialValues.phone !== "-999") return initialValues.phone;
          return "";
        })(),
      });
      setDataAggregator(data);
    });

    const info = {
      token: token,
      phone: phoneUrl !== null && phoneUrl !== "-999" ? phoneUrl : "",
      birthdate: birthdateUrl,
    };

    apiRegisterHubspot(info);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    init("init_in_rastreator");

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showComponent && !isMobile) {
      setNotification({
        message: translate("rastreator.stickyBanner.message"),
        positionX: "right",
        color: "purple",
        positionY: "bottom",
        buttonAlertConfig: {
          show: true,
          config: {
            onClick: () => scrollToDiv(),
            buttonType: "text",
            className: "!text-white ",
            label: translate("rastreator.form.button.label"),
            noHover: true,
            iconPosition: "right",
            icon: GlobalIcon({ iconName: "ArrowRightIcon" }),
          },
        },
      });
      nodeElementAlert.current.handleShowPortal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showComponent]);

  useEffect(() => {
    setNavbarStickyInfo({
      show: isMobile && showComponent,
      content: NavbarStickyContent({
        discountPrice: dataAggregator?.yearlyPrice,
        originalPrice: dataAggregator?.originalPrice,
      }),
      actions: NavbarStikyButton({
        label: translate("rastreator.form.button.label"),
        onClick: scrollToDiv,
      }),

      position: "bottom",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, showComponent]);

  const prepareData = (form: any) => {

    const birthdateFinish = new Date(birthdateUrl!).toISOString();
    return {
      email: form.email,
      phone: form.phone,
      birthdate: birthdateFinish,
      zipCode: dataAggregator?.zipCode,
      gender: dataAggregator?.gender,
      capital: dataAggregator?.capital,
      insuranceType: dataAggregator?.coverage,
    };
  };

  const sendData = (form: any) => {
    leadRepository
      .partnerRastreator({ token, data: prepareData(form) })
      .then((response) => {
        if (response !== "error") {
          setToken(response.token || "");
          setLeadId(response.id);
          setUserStatus(response.status);

          const dataInfo = {
            calculatedCapital: parseFloat(dataAggregator.capital),
            calculatedPremium: parseFloat(dataAggregator.yearlyPrice),
            coverage: dataAggregator.coverage === "premium" ? "FIPA" : "F",
            email: form.email,
            intent: undefined,
            leadId: response.id,
            page: window.location.href,
            phone: form.phone,
            postalCode: dataAggregator?.zipCode,
            utm_source: utm_source,
            utm_campaign: utm_campaign,
            utm_medium: utm_medium,
            utm_content: utm_content,
            utm_term: utm_term,
            utm_test: utm_test,
            gclid: gclid,
            msclkid: msclkid,
            fbclid: fbclid,
            platform: "App",
            entryPage: getEntryPage(),
            referrerUrl: getReferrerUrl(),
            ABTestVersion: getABTestVersion()
          };

          if (response.token) {
            const event = "leadCreated";

            handleTrackerQuestion({
              type: "tracker",
              data: {
                leadUlid: response.id,
                campaign: "rastreator",
              },
            });

            handleTrackerQuestion({
              type: "FS",
              event,
              data: {
                birthDate: birthdateUrl ? new Date(birthdateUrl) : undefined,
                ...dataInfo,
              },
            });
            handleTrackerQuestion({
              type: "GA",
              data: {
                event,
                birthDate: birthdateUrl ? birthdateUrl : undefined,
                ...dataInfo,
              },
            });
          }

          const event = "calculatorQuoteGenerated";

          handleTrackerQuestion({
            type: "FS",
            event,
            data: {
              birthDate: birthdateUrl ? new Date(birthdateUrl) : undefined,
              ...dataInfo,
            },
          });
          handleTrackerQuestion({
            type: "GA",
            data: {
              event,
              birthDate: birthdateUrl ? birthdateUrl : undefined,
              ...dataInfo,
            },
          });
          identifyFS({
            lead: response.id,
            data: {
              emailDomain: form.email && form.email.split("@")[1],
              email: form.email,
              brokerId: "null",
            },
          });
          navigate("/productQuestions");
        }
      });
  };

  return {
    dataAggregator,
    initialValues,
    isMobile,
    sendData,
    translate
  };
};

export default RastreatorController;
