import { ButtonDS, Illustration, Typography, useDevice } from "get-life-storybook-ts";

import "./PendingValidation.scss";
import { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../context/i18n.context";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import { PHONE, DOMAIN } from "../../constants/Globals";
import { IllustrationT } from "get-life-storybook-ts/lib/components/Illustration/Illustration";

export default function PendingValidation() {
  const [illustration, setIllustration] = useState<IllustrationT>("SigningIllustration");
  const {
    state: { translate },
  } = useContext(I18nContext);

  const { handleCallMe } = useContext(GetlifeContext);

  const { isMobile } = useDevice()

  const getRandomizedIllustration = (): IllustrationT => {
    const illustrations: IllustrationT[] = ["ReadingIllustration"];
    return illustrations[Math.floor(Math.random() * illustrations.length)];
  };

  useEffect(() => {
    setIllustration(getRandomizedIllustration());
  }, []);

  return (
    <section className="pendingValidation-section">
      <div className="pendingValidation-illustration">
      {
        DOMAIN !== "wl_es" ? (
           <Illustration
            illustration={illustration}
            height={isMobile ? 200 : 264}
            width={isMobile ? 200 : 264}
          />
        ) : (
          <img src="https://storage.googleapis.com/getlife-bucket-1/public/image/wl_validation.svg" width={isMobile ? "200px" : "264px"} height={isMobile ? "200px" : "264px"}/>
        )
      }
             </div>
      <Typography
        variant="H1"
        text={translate("pendingValidation.title")}
        weight="700"
        className="pendingValidation-title"
      />
      <Typography
        variant="Body"
        weight="400"
        text={""}
        className="pendingValidation-body"
      >
        <span
          dangerouslySetInnerHTML={{
            __html: translate("pendingValidation.description"),
          }}
        />
      </Typography>
      <div className="pendingValidation-buttons">
        <ButtonDS
          label={translate("pendingValidation.call")}
          leftIcon="PhoneCallIcon"
          className="pendingValidation-button"
          href={`tel:${PHONE}`}
        />
        <ButtonDS
          label={translate("pendingValidation.bookCall")}
          leftIcon="HeadPhones"
          buttonType="secondary"
          className="pendingValidation-button"
          onClick={handleCallMe}
        />
      </div>
    </section>
  );
}
