import { useContext, useState } from "react";
import { useLocation } from "react-router";
import { GetlifeContext, LanguageT } from "../../../context/GetLifeContext.context";
import { I18nContext } from "../../../context/i18n.context";
import {
  FormBuilderContext,
  validateType,
} from "../../../context/FormBuilder.context";
import { useDevice } from "get-life-storybook-ts";
import { optionDateList } from "../CalendarStartDate/CalendarStartDate.component";
import { range } from "./FormBuilder";
import moment from "moment";
import { DayAvailable, DaysAvailable } from "../../../utils/InternationlChanges";

export type ComponentNameT =
  | "intention_question"
  | "income_question"
  | "start_date_question"
  | "spain_residence_question"
  | "spain_nationality_question"
  | "income_question"
  | "smoke_question"
  | "weight_question"
  | "height_question"
  | "risky_profession_question"
  | "hospitalised_question"
  | "prp_worker_question"
  | "external_underwriting"
  | "france_residence_question"
  | "italy_residence_question"
  | "prp_question"
  | "gender_question"
  | "postal_code_question"
  | "email_question"
  | "phone_question"

export type ComponentNameExtendedT =
  | ComponentNameT
  | "handicapped_question"
  | "axa_v6_group_questions_radio"
  | "axa_v6_group_questions"
  | "beneficiariesQuestions";

export interface AnswersI {
  value: string;
  text: string;
  label: string;
  icon?: string;
}

interface FormBuilderControllerI {
  question: any;
  answer: any;
  answers: AnswersI[];
  capitalLimits: any;
  componentName: ComponentNameT;
  error: any;
  handleSubmit: () => void;
  handleValues: (e: any) => void;
  isOneChoinceQuestionRoute?: string;
  name: string;
  subtitle: string | null;
  type: string;
}


const FormBuilderController = ({
  question,
  answers,
  capitalLimits,
  handleSubmit,
  handleValues,
  name,
  subtitle,
  type,
  componentName,
  isOneChoinceQuestionRoute,
}: FormBuilderControllerI) => {
  const {
    state: { translate },
  } = useContext(I18nContext);

  const { domain, leadId, token, loading } = useContext(GetlifeContext);
  const { formError, disabled, checkedConditions, setCheckedConditions } = useContext(FormBuilderContext);
  const { isMobile } = useDevice();
  const [subtitleDate, setSubtitleDate] = useState<string | null>(subtitle);
  const isOneChoinceQuestion =
    type === "radio" && componentName && isOneChoinceQuestionRoute;

  const location = useLocation();
  const actualUrl = location.pathname;

const isQuestionSelector = componentName as ComponentNameExtendedT === "axa_v6_group_questions" && type === "select_multiple";

  const handleChange = (e: any) => {
    handleValues(e);
    if (type === "calendar_extended") {
      const text = `${translate("productQuestions.calendar.result")} ${moment(
        e
      ).format("DD-MM-YYYY")}`;
      setSubtitleDate(text + " " + subtitle);
    }
  };

  const initialValue = () => {
    if (type === "calendar_extended") return DaysAvailable[domain] - DayAvailable[domain];
    if (componentName === "spain_nationality_question") return null;
    return question &&
      question?.previousIllnessesAnswered &&
      question?.previousIllnessesAnswered.length
      ? question?.previousIllnessesAnswered
      : isQuestionSelector ? [] : "";
  };

  const prepareLabel = (label: string) => {
    if (label !== null && label !== undefined) {
      if (label.includes("altura")) {
        return translate("productQuestions.label.height");
      }

      if (label.includes("peso")) {
        return translate("productQuestions.label.weight");
      }

      if (label.includes("profesión se trata")) {
        return translate("productQuestions.label.riskyProfession");
      }

      if (label.includes("enfermedades")) {
        return translate("productQuestions.label.diseases");
      }

      if (label.includes(" ha tenido síntomas ")) {
        return translate("productQuestions.label.sintoms");
      }

      if (label.includes(" de forma habitual")) {
        return translate("productQuestions.label.sports");
      }

      if (label.includes("trabajas")) {
        return translate("productQuestions.label.workActually");
      }

      if (label.includes("contrato")) {
        return translate("productQuestions.label.contract");
      }

      if (label.includes("capital")) {
        return translate("productQuestions.label.capital");
      }

      if (label.includes("deporte")) {
        return translate("productQuestions.label.sports");
      }

      if (label.includes("votre profession")) {
        return translate("step4.label.profession");
      }

      if (label.includes("situation maritale")) {
        return translate("step4.label.maritale");
      }

      if (label.includes("d'enfants à charge")) {
        return translate("step4.label.enfants");
      }
    }

    return translate("question.select.base");
  };

  const typeInputQuestions = (componentName: string) => {
    if (validateType.includes(componentName) && componentName !== "email_question") {
      return "number";
    } else {
      return "text";
    }
  };

  const specialRadioInputs: any = {
    spain_residence_question: [
      {
        value: "yes",
        text: "Sí",
        label: translate("yes"),
        illustration: "SpainIcon",
        variant: "Illustration"
      },
      {
        value: "no",
        text: "No",
        label: translate("no"),
        illustration: "PlanetIcon",
        variant: "Illustration"
      },
    ],
    spain_nationality_question: [
      {
        value: "ES",
        text: "Sí",
        label: translate("yes"),
      },
      {
        value: "",
        text: "No",
        label: translate("no"),
      },
    ],
    smoke_question: [
      {
        value: "yes",
        text: "Sí",
        label: translate("yes"),
        illustration: "SmokerIcon",
        variant: "Illustration"
      },
      {
        value: "no",
        text: "No",
        label: translate("no"),
        illustration: "NonSmokerIcon",
        variant: "Illustration"
      },
    ],
    prp_worker_question: [
      {
        value: "yes",
        text: "Sí",
        label: translate("yes"),
        illustration: "PublicIcon",
        variant: "Illustration"
      },
      {
        value: "no",
        text: "No",
        label: translate("no"),
        illustration: "PersonIcon",
        variant: "Illustration"
      },
    ],
    france_residence_question: [
      {
        value: "yes",
        text: "Sí",
        label: translate("yes"),
        illustration: "FranceIcon",
        variant: "Illustration"
      },
      {
        value: "no",
        text: "Non",
        label: translate("no"),
        illustration: "PlanetIcon",
        variant: "Illustration"
      },
    ],
    italy_residence_question: [
      {
        value: "yes",
        text: "Sí",
        label: translate("yes"),
        illustration: "ItalyIcon",
        variant: "Illustration"
      },
      {
        value: "no",
        text: "Non",
        label: translate("no"),
        illustration: "PlanetIcon",
        variant: "Illustration"
      },
    ],
    gender_question: [
      {
        value: "Male",
        text: translate("calculatorPage.sexM"),
        label: translate("calculatorPage.sexM"),
        illustration: "MaleIcon",
        variant: "Illustration"
      },
      {
        value: "Female",
        text: translate("calculatorPage.sexF"),
        label: translate("calculatorPage.sexF"),
        illustration: "FemaleIcon",
        variant: "Illustration"
      },
    ],
    risky_profession_question: [
      {
        value: "yes",
        text: "Sí",
        label: translate("yes"),
        illustration: "ClimbIcon",
        variant: "Illustration"
      },
      {
        value: "no",
        text: "No",
        label: translate("no"),
        illustration: "WaterPlantIcon",
        variant: "Illustration"
      },
    ]
  };

  const beneficiariesOptions = {
    "en": [],
    "pt": [],
    "es" : [
      {
        value: "Herederos Legales",
        label: translate("questions.beneficiaries.legalHeirs"),
      },
      {
        value: "Orden de Prelación",
        label: translate("questions.beneficiaries.orderOfPrecedence"),
      },
      {
        value: "other",
        label: translate("questions.beneficiaries.otherBeneficiaries"),
      },
    ],
    "fr": [
      {
        value: "Herederos Legales",
        label: translate("questions.beneficiaries.legalHeirs"),
      },
      {
        value: "other",
        label: translate("questions.beneficiaries.otherBeneficiaries"),
      },
    ],
    "it": [
      {
        value: "Legal heirs",
        label: translate("questions.beneficiaries.legal")
      },
      {
        value: "My children",
        label: translate("questions.beneficiaries.children")
      },
      {
        value: "Testament heirs",
        label: translate("questions.beneficiaries.testament")
      },
      {
        value: "My spouse",
        label: translate("questions.beneficiaries.spouse")
      }
    ],
    "wl_es" : [
      {
        value: "Herederos Legales",
        label: translate("questions.beneficiaries.legalHeirs"),
      },
      {
        value: "Orden de Prelación",
        label: translate("questions.beneficiaries.orderOfPrecedence"),
      },
      {
        value: "other",
        label: translate("questions.beneficiaries.otherBeneficiaries"),
      },
    ],
    "sql_es" : [
      {
        value: "Herederos Legales",
        label: translate("questions.beneficiaries.legalHeirs"),
      },
      {
        value: "Orden de Prelación",
        label: translate("questions.beneficiaries.orderOfPrecedence"),
      },
      {
        value: "other",
        label: translate("questions.beneficiaries.otherBeneficiaries"),
      },
    ],
  }

  const radioButtonOptions =
    name === "ipa"
      ? [
          {
            value: "basic",
            label: translate("questions.ipa.basic"),
          },
          {
            value: "premium",
            label: translate("questions.ipa.premium"),
          },
        ]
      : name === "gender"
      ? [
          {
            value: "Male",
            label: translate("questions.gender.male"),
            text: "Hombre",
          },
          {
            value: "Female",
            label: translate("questions.gender.female"),
            text: "Mujer",
          },
        ]
      : name === "beneficiaries"
      ? beneficiariesOptions[domain as LanguageT]
      : answers;

  const supportText = (componentName: ComponentNameT) => {
    return componentName === "weight_question" ||
      componentName === "height_question"
      ? null
      : subtitle;
  };

  const options = () => {
    if (type === "calendar_extended") {
      return optionDateList(translate, domain);
    } else if (Object.keys(specialRadioInputs).includes(componentName)) {
      return specialRadioInputs[componentName];
    } else if (type === "capital") {
      return range({
        initial: capitalLimits.capitalRangueMin,
        final: capitalLimits.capitalRangueMax,
        step: capitalLimits.capitalRangueStep,
      }).reverse();
    }
    return radioButtonOptions;
  };

  const singleSelectInputs = [
    "income_question",
    "gender_question",
    "spain_residence_question",
    "spain_nationality_question",
    "smoke_question",
    "risky_profession_question",
    "hospitalised_question",
    "prp_worker_question",
    "france_residence_question",
    "italy_residence_question",
    "boolean_question",
    "select_button",
    "axa_v6_group_questions_radio",
    "handicapped_question"
  ];

  const typeInpust: any = {
    singleSelect: singleSelectInputs,
    checkbox: ["checkbox_optional", "checkbox_mandatory", "intention_question"],
    input: ["text", "number"],
    select: ["select_search", "select_multiple"],
    email: ["email_question"]
  };

  const typeInputsTransform = (type: any) => {
    let finalType;
    Object.keys(typeInpust).forEach((key) => {
      if (
        typeInpust[key].includes(type) ||
        typeInpust[key].includes(componentName)
      ) {
        finalType = key;
      }
    });
    return finalType || type;
  };

  const formInputs = [
    "calendar_simple",
    "capital",
    "formAdress",
    "formBeneficiariesBankingEntity",
    "formBeneficiariesLegalPerson",
    "formBeneficiariesPhysicalPerson",
    "idCard",
    "nationality"
  ];

  return {
    actualUrl,
    checkedConditions,
    disabled,
    domain,
    formError,
    formInputs,
    handleChange,
    initialValue,
    isMobile,
    isOneChoinceQuestion,
    leadId,
    loading,
    options,
    prepareLabel,
    subtitleDate,
    setCheckedConditions,
    supportText,
    token,
    translate,
    typeInputQuestions,
    typeInputsTransform
  };
};

export default FormBuilderController;
