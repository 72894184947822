import { useContext, useEffect, useState } from "react";
import { GetlifeContext } from "../../../context/GetLifeContext.context";
import { useLocation, useNavigate } from "react-router";
import { AnswersI } from "../../../components/Components/FormBuilder/FormBuilder.controller";
import { GetLifeTrackerContext } from "../../../context/GetLifeTracker.context";
import HttpLeadRepository from "../../../api/request/Lead/Lead.service";
import { useDevice } from "get-life-storybook-ts";
import { applyPromotion } from "../../../hooks/Promotions/applyPromotion";
import { getCookie } from "../../../hooks/getCookie";

interface DataI {
  answer: AnswersI | string | null;
  countBack: number;
  idUsed: any[];
  policy: boolean;
  question: any;
}

interface PopUpI {
  callMe: boolean;
}

const StepsCalculatorPageController = () => {
  const {
    init,
    domain,
    leadId,
    loading,
    progressBar,
    setLeadId,
    setLoading,
    setProgressBar,
    setShowHeader,
    setToken,
    token,
    userStatus,
    utm_campaign,
    utm_source,
    utm_medium, utm_content,
    utm_term,
    utm_test,
    gclid,
    msclkid,
    fbclid,
    sendEventsNewLead,
    setSendEventsNewLead,
    nodeMaxCapitalModal
  } = useContext(GetlifeContext);
  const { handleTrackerQuestion } = useContext(GetLifeTrackerContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState<DataI>({
    answer: "",
    countBack: 1,
    idUsed: [],
    policy: false,
    question: [],
  });

  const [popUp, setPopUp] = useState<PopUpI>({
    callMe: false,
  });

  const [responseCode, setResponseCode] = useState<number | boolean | string>(
    true
  );

  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [questionStack, setQuestionStack] = useState<number[]>([]);
  const { isMobile } = useDevice();

  const answer: string | null =
    data.answer && typeof data.answer !== "string"
      ? data.answer.value
      : data.answer;

  const leadRepository = new HttpLeadRepository(leadId, token);

  const handleCallMe = () => {
    setPopUp({ ...popUp, callMe: !popUp.callMe });
  };

  const handleShowHeader = (isDisplayed: boolean) => {
    setShowHeader(!(isMobile && isDisplayed));
  };

  const refreshNextQuestion = async () => {
    const progressIncrement =
      typeof progressBar === "number" && progressBar <= 95
        ? progressBar + 5
        : 100;
    setLoading(true);

    try {
      const response = await leadRepository.getQuestion();
      if (response.code === 401) {
        throw new Error();
      } else {
        leadRejectedEvent(response.code);
        setData({
          ...data,
          answer: response.type === "checkbox_optional" ? "none" : null,
          question: response,
        });
        setResponseCode(response.code);
        setPopUp({
          callMe: false,
        });
        if (response.questionId && userStatus === "questions_pending") {
          const eventData = {
            questionId: response.questionId,
            stack: "Carrier",
            leadId: leadId,
            page: window.location.href,
            platform: "App",
            pageTitle: "Life5 D2C - QuestionViewed",
          };

          handleTrackerQuestion({
            type: "GA",
            data: {
              event: "virtualPageview",
              ...eventData,
            },
          });
          handleTrackerQuestion({
            type: "FS",
            event: "Question Viewed",
            data: eventData,
          });
        }
      }
      setLoading(false);
      setProgressBar(progressIncrement);

      if (response.newCapital) {
        nodeMaxCapitalModal.current?.handleShowPortal();
      }
    } catch (error: any) {
      if (error.status === 405) {
        setLoading(false);
        navigate("/telesubscription");
      }
      init("step4_catch_get_question");
    }
  };

  const saveQuestion = async () => {
    const { questionId, componentName } = data.question;

    try {
      await leadRepository.saveQuestion({
        answer:
          data.question.type === "number"
            ? answer!.replaceAll(/[^0-9]+/g, "")
            : answer,
        questionId,
      });

      if (componentName === "email_question") {
        // Autoapply coupon
        if (sendEventsNewLead === "false") {
          const gtf_campaign = getCookie("gtf_campaign");
          applyPromotion({ leadId: leadId, promotion: gtf_campaign || "3MESES1E", price: 0, noValidationNeeded: true });
          handleTrackerQuestion({
            type: "FS",
            event: `appliedCoupon`,
            data: {
              promotionalCode: gtf_campaign || "3MESES1E",
              leadId: leadId,
              market: domain
            },
          });
        }

        setSendEventsNewLead("true");
      }

      setQuestionStack([...questionStack, questionId]);

      const eventData = {
        questionId: questionId,
        stack: "Carrier",
        leadId: leadId,
        page: window.location.href,
        platform: "App",
        answerId: answer,
        pageTitle: "Life5 D2C - QuestionAnswered",
      };

      handleTrackerQuestion({
        type: "FS",
        event: "Question Answered",
        data: {
          answerString: answer,
          ...eventData,
        },
      });
      handleTrackerQuestion({
        type: "GA",
        data: {
          event: "virtualPageview",
          answers: answer,
          ...eventData,
        },
      });
      if (!data.idUsed.includes(questionId)) {
        setData({ ...data, idUsed: questionId });
      }
      setIsFirst(false);
      refreshNextQuestion();
    } catch (error: any) {
      if (error.status === 409) {
        navigate("/login")
      }
      init("step4_catch_save_question");
    }
    setData({ ...data, countBack: 1 });
  };

  const nextQuestion = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setLoading(true);
    saveQuestion();
  };

  const leadRejectedEvent = (responseCode: number | string) => {
    if (responseCode === -1 || responseCode === "rejected") {
      const event = "leadRejected";
      const eventData = {
        leadId: leadId,
        page: window.location.href,
        utm_source: utm_source,
        utm_campaign: utm_campaign,
        utm_medium: utm_medium,
        utm_content: utm_content,
        utm_term: utm_term,
        utm_test: utm_test,
        gclid: gclid,
        msclkid: msclkid,
        fbclid: fbclid,
      };

      handleTrackerQuestion({
        type: "GA",
        data: {
          event,
          ...eventData,
        },
      });
      handleTrackerQuestion({
        type: "FS",
        event,
        data: eventData,
      });
    }
  };

  const lastQuestion = async () => {

    if (questionStack.length > 0) {
      setLoading(true);
      const progressDecrement =
        typeof progressBar === "number" ? progressBar - 5 : progressBar;
      let lastQuestionId = questionStack[(questionStack.length - 1)];

      if (lastQuestionId !== undefined) {
        setData({
          ...data,
          question: lastQuestionId,
          countBack: data.countBack + 1,
        });
      }

      try {
        const response = await leadRepository.editQuestion(lastQuestionId);
        setData({
          ...data,
          answer: response.type === "checkbox_optional" ? "none" : null,
          question: response,
        });
        setPopUp({
          callMe: false,
        });
        setResponseCode(response.code);
        setProgressBar(progressDecrement);
        setLoading(false);
      } catch (error) { }
    }
  };

  useEffect(() => {
    if (responseCode === 0) {
      setProgressBar(false);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
      navigate("/payment/1" + location.search);
    } else if (responseCode === -1 || responseCode === "rejected") {
      leadRejectedEvent(responseCode);
      setProgressBar(false);
      navigate("/notcoverage");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseCode]);

  return {
    data,
    handleCallMe,
    handleLeadId: setLeadId,
    handleShowHeader,
    handleToken: setToken,
    lastQuestion,
    leadId,
    loading,
    nextQuestion,
    popUp,
    questionStack,
    refreshNextQuestion,
    saveQuestion,
    setData,
    token,
  };
};

export default StepsCalculatorPageController;
