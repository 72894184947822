import React from "react";
import { useNavigate } from "react-router";
import HttpCouponsRepository from "../../api/request/Coupons/Coupons.service";
import HttpLeadRepository from "../../api/request/Lead/Lead.service";
import { GetlifeContext } from "../../context/GetLifeContext.context";
import { I18nContext } from "../../context/i18n.context";
import { calculateDiffDays, formatDate } from "../../utils/Dates.utils";
import numberFormat, {
  capitalize,
  numberFormatPayment,
} from "../../utils/Utils";
import { redirect } from "../../Routes/redirect";
import { DOMAIN } from "../../constants/Global";
import { renderCoverages } from "../../utils/dataBlockCoverages";

export type DataBlockIconT =
  | "card"
  | "user"
  | "check"
  | "umbrella"
  | "calendar";

interface DataBlockInfoI {
  title: string;
  subtitle: string;
  text: string | React.ReactNode;
  icon: DataBlockIconT;
  space: boolean;
}

const ConfirmationPageSQLController = () => {
  const { leadId, token, handleCallMe, userStatus, setUserStatus } =
    React.useContext(GetlifeContext);
  const {
    state: { translate },
  } = React.useContext(I18nContext);
  const [data, setData] = React.useState<any>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [couponInfo, setCouponInfo] = React.useState<any>();
  const [progression, setProgression] = React.useState<any>();
  const [dataBlocksInfo, setDataBlocksInfo] =
    React.useState<DataBlockInfoI[]>();

  const leadRepository = new HttpLeadRepository(leadId, token);
  const couponRepository = new HttpCouponsRepository(leadId, token);
  const navigate = useNavigate();

  const intentionMessage = (info: any) => {
    const intention = info?.intention;
    if (intention.length === 1) {
      return translate("confirmationPage.intention.protect").replace(
        "$",
        translate(`confirmationPage.intention.${intention[0]}`)
      );
    } else if (intention.length === 2) {
      const combineIntention = `${translate(
        `confirmationPage.intention.${intention[0]}`
      )} ${translate("confirmationPage.intention.conjuction")} ${translate(
        `confirmationPage.intention.${intention[1]}`
      )}`;
      return translate("confirmationPage.intention.protect").replace(
        "$",
        combineIntention
      );
    } else {
      const combineIntention = `${translate(
        `confirmationPage.intention.${intention[0]}`
      )}, ${translate(
        `confirmationPage.intention.${intention[1]}`
      )} ${translate("confirmationPage.intention.conjuction")} ${translate(
        `confirmationPage.intention.${intention[2]}`
      )}`;
      return translate("confirmationPage.intention.protect").replace(
        "$",
        combineIntention
      );
    }
  };

  const continueProcess = () => {
    navigate(redirect(userStatus));
  };

  const fetch = async () => {
    const response = await leadRepository.getConfirmationInfo();
    setUserStatus(response.status);
    setData(response);
    console.log(response);

    const isPromoted = response?.promo ? true : false;
    const totalPrice =
      response?.period?.toLowerCase() === "yearly"
        ? !isPromoted
          ? response?.prices?.yearly?.real
          : response?.prices?.yearly?.promoted
        : !isPromoted
        ? response?.prices?.monthly?.real
        : response?.prices?.monthly?.promoted;
    setProgression([
      {
        description: translate(
          "confirmationPage.Progression.confirmMethod.description"
        ),
        title: translate("confirmationPage.Progression.confirmMethod.title"),
        active: "active",
      },
      {
        description: translate(
          "confirmationPage.Progression.personalData.description"
        ),
        title: translate("confirmationPage.Progression.personalData.title"),
        active: false,
      },
      {
        description: translate(
          "confirmationPage.Progression.final.description"
        ),
        title: translate("confirmationPage.Progression.final.title"),
        active: false,
      },
    ]);

    setDataBlocksInfo([
      {
        title: translate("confirmationPage.resume.insured"),
        subtitle: `${capitalize(response.name)}`,
        text: formatDate(response.birthDate),
        icon: "user",
        space: false,
      },
      {
        title: translate("confirmationPage.resume.capital"),
        subtitle: numberFormat(response.sumCapitals, "capital") || "",
        text: intentionMessage(response),
        icon: "check",
        space: true,
      },
      {
        title: translate("confirmationPage.resume.coverage"),
        subtitle:
          response.coverage === "basic"
            ? translate("confirmationPage.resume.insurance")
            : response.coverage === "eg"
            ? translate("confirmationPage.resume.eg")
            : translate("confirmationPage.resume.insuranceIPA"),
        text: renderCoverages(response.coverages, translate),
        icon: "umbrella",
        space: true,
      },
      {
        title: translate("confirmationPage.resume.startDate"),
        subtitle: new Date(response.effectDate).toLocaleDateString(),
        text: `${calculateDiffDays(response.effectDate, translate)} `,
        icon: "calendar",
        space: true,
      },
      {
        title: translate("confirmationPage.resume.bonus"),
        subtitle: `${numberFormatPayment(totalPrice)} €`,
        text: translate(
          `confirmationPage.period.${response.period?.toLowerCase()}`
        ),
        icon: "card",
        space: true,
      },
    ]);
  };

  React.useEffect(() => {
    fetch();
  }, []);

  return {
    continueProcess,
    couponInfo,
    data,
    dataBlocksInfo,
    handleCallMe,
    intentionMessage,
    open,
    progression,
    setOpen,
    translate,
  };
};

export default ConfirmationPageSQLController;
