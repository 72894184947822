import { IconNameT } from "get-life-storybook-ts";
import { LanguageT } from "../context/GetLifeContext.context";
import { getCookie } from "../hooks/getCookie";
import { ENV } from "../constants/Global";

type PhonePrefixI = {
  [key in LanguageT]: { prefix: string; replace: string };
};

type MaxAgeI = {
  [key in LanguageT]: { minAge: number; maxAge: number };
};

type FinishStepsI = {
  [k in LanguageT]: { final_step: string; progress: number };
};

type GlobalInsuranceTypeI = {
  [k in LanguageT]: boolean;
};

type GlobalKeyStringI = {
  [k in LanguageT]: string;
};

type GlobalKeyStringArrayI = {
  [k: string]: string[];
}

type GlobalKeyIconI = {
  [k in LanguageT]: IconNameT;
};

type GlobalKeyStringOrNullI = {
  [k in LanguageT]: string | null;
}

type GlobalKeyNumberI = {
  [k in LanguageT]: number;
}

export const PhonePrefix: PhonePrefixI = {
  es: { prefix: "0034", replace: "" },
  en: { prefix: "0034", replace: "" },
  fr: { prefix: "0033", replace: "0" },
  pt: { prefix: "0034", replace: "" },
  it: { prefix: "0039", replace: "" },
  wl_es: { prefix: "0034", replace: "" },
  sql_es: { prefix: "0034", replace: "" },
};

export const DaysAvailable = {
  es: 90,
  en: 90,
  fr: 30,
  pt: 90,
  it: 30,
  wl_es: 90,
  sql_es: 90
};

export const DayAvailable = {
  es: 0,
  en: 0,
  fr: 0,
  pt: 0,
  it: 1,
  wl_es: 0,
  sql_es: 0
};

export const AgeValidation: MaxAgeI = {
  es: { minAge: 18, maxAge: 75 },
  en: { minAge: 18, maxAge: 75 },
  fr: { minAge: 18, maxAge: 75 },
  pt: { minAge: 18, maxAge: 75 },
  it: { minAge: 19, maxAge: 65 },
  wl_es: { minAge: 18, maxAge: 75 },
  sql_es: { minAge: 18, maxAge: 75 }
};

export const MULTILANG: FinishStepsI = {
  es: { final_step: "4", progress: 25 },
  fr: { final_step: "9", progress: 11 },
  en: { final_step: "4", progress: 25 },
  pt: { final_step: "4", progress: 25 },
  it: { final_step: "4", progress: 25 },
  wl_es: { final_step: "4", progress: 25 },
  sql_es: { final_step: "4", progress: 25 }
};

export const GlobalInsuranceType: GlobalInsuranceTypeI = {
  es: false,
  fr: true,
  en: true,
  pt: true,
  it: true,
  wl_es: false,
  sql_es: false
}
export const GlobalOnlyPremium: GlobalInsuranceTypeI = {
  es: false,
  fr: true,
  en: false,
  pt: false,
  it: true,
  wl_es: false,
  sql_es: false
};

export const GlobalAllowTelesubscription: GlobalInsuranceTypeI = {
  es: true,
  fr: false,
  en: false,
  pt: false,
  it: false,
  wl_es: true,
  sql_es: true
};

export const GlobalIllnes: GlobalKeyStringI = {
  es: "calculatorPage.textAdditionalProtectionIPA",
  fr: "calculatorPage.textAdditionalProtectionIPA",
  en: "calculatorPage.textAdditionalProtectionIPA",
  pt: "calculatorPage.textAdditionalProtectionIPA",
  it: "calculatorPage.textAdditionalProtectionTerminalIllness",
  wl_es: "calculatorPage.textAdditionalProtectionIPA",
  sql_es: "calculatorPage.textAdditionalProtectionIPA",
};

export const GlobalIllnesTitle: GlobalKeyStringI = {
  es: "calculatorPage.textAdditionalProtectionIPA",
  fr: "calculatorPage.textAdditionalProtectionIPA",
  en: "calculatorPage.textAdditionalProtectionIPA",
  pt: "calculatorPage.textAdditionalProtectionIPA",
  it: "newUpsell.resumeCart.terminalIllness.modalTitle",
  wl_es: "calculatorPage.textAdditionalProtectionIPA",
  sql_es: "calculatorPage.textAdditionalProtectionIPA",
};

export const GlobalIllnesIcon: GlobalKeyIconI = {
  es: "WheelChairIcon",
  fr: "WheelChairIcon",
  en: "WheelChairIcon",
  pt: "WheelChairIcon",
  it: "SaveMoneyIcon",
  wl_es: "WheelChairIcon",
  sql_es: "WheelChairIcon",
};

export const GlobalIllnesContent: GlobalKeyStringI = {
  es: "calculatorPage.textAdditionalProtectionInfo",
  fr: "calculatorPage.textAdditionalProtectionInfo",
  en: "calculatorPage.textAdditionalProtectionInfo",
  pt: "calculatorPage.textAdditionalProtectionInfo",
  it: "newUpsell.resumeCart.terminalIllness.modalContent",
  wl_es: "calculatorPage.textAdditionalProtectionInfo",
  sql_es: "calculatorPage.textAdditionalProtectionInfo",
};

export const GlobalProvinceApi: GlobalKeyStringI = {
  es: "province_spain",
  fr: "province_france",
  en: "province_spain",
  pt: "province_spain",
  it: "city_italia",
  wl_es: "province_spain",
  sql_es: "province_spain",
}

export const GlobalCountryApi: GlobalKeyStringI = {
  es: "country",
  fr: "country_fr",
  en: "country",
  pt: "country",
  it: "country_it",
  wl_es: "country",
  sql_es: "country",
}

export const GlobalUrlFaqs: GlobalKeyStringI = {
  es: "https://life5.es/preguntas-frecuentes/",
  fr: "https://life5.fr/faq/",
  en: "https://life5.es/preguntas-frecuentes/",
  pt: "https://life5.es/preguntas-frecuentes/",
  it: "https://life5.it/faq/",
  wl_es: "https://life5.es/preguntas-frecuentes/",
  sql_es: "https://life5.es/preguntas-frecuentes/",
}

export const GlobalHubspotLinks: GlobalKeyStringI = {
  es: "https://meetings-eu1.hubspot.com/asesores",
  fr: "https://meetings-eu1.hubspot.com/life5/support-client",
  en: "https://meetings-eu1.hubspot.com/asesores",
  pt: "https://meetings-eu1.hubspot.com/asesores",
  it: "https://meetings-eu1.hubspot.com/stefano-passarelli",
  wl_es: "https://meetings-eu1.hubspot.com/asesores",
  sql_es: "https://meetings-eu1.hubspot.com/asesores",
}

export const GlobalEmails: GlobalKeyStringI = {
  es: "elena@life5.com",
  fr: "support@life5.com",
  en: "elena@life5.com",
  pt: "elena@life5.com",
  it: "supporto@life5.it",
  wl_es: "elena@life5.com",
  sql_es: "elena@life5.com",
}

export const GlobalPositionEmails: GlobalKeyStringI = {
  es: "asesores@life5.com",
  fr: "support@life5.com",
  en: "asesores@life5.com",
  pt: "asesores@life5.com",
  it: "supporto@life5.it",
  wl_es: "asesores@life5.com",
  sql_es: "asesores@life5.com",
}

export const GlobalTypeFormPolicy: GlobalKeyStringI = {
  es: "https://kex36vozazx.typeform.com/to/p1a5pU3Z",
  fr: "https://kex36vozazx.typeform.com/to/rCzIrx8A",
  en: "https://kex36vozazx.typeform.com/to/p1a5pU3Z",
  pt: "https://kex36vozazx.typeform.com/to/p1a5pU3Z",
  it: "https://kex36vozazx.typeform.com/to/p9sKWAFF",
  wl_es: "https://kex36vozazx.typeform.com/to/p1a5pU3Z",
  sql_es: "https://kex36vozazx.typeform.com/to/p1a5pU3Z",
}

export const ConditionsLink: GlobalKeyStringI = {
  es: "https://life5.es/condiciones-generales-contratacion/",
  fr: "https://life5.fr/cgu/",
  en: "https://life5.es/condiciones-generales-contratacion/",
  pt: "https://life5.es/condiciones-generales-contratacion/",
  it: (() => {
    if (ENV === "PRD") return "https://life5.it/termini-condizioni";
    return "https://test.life5.it/termini-condizioni";
  })(),
  wl_es: "https://life5.es/condiciones-generales-contratacion/",
  sql_es: "https://life5.es/condiciones-generales-contratacion/",
}

export const PrivacityLink: GlobalKeyStringI = {
  es: "https://life5.es/politica-privacidad-resumida/",
  fr: "https://life5.fr/politique-confidentialite/",
  en: "https://life5.es/politica-privacidad-resumida/",
  pt: "https://life5.es/politica-privacidad-resumida/",
  it: (() => {
    if (ENV === "PRD") return "https://life5.it/informativa-privacy";
    return "https://test.life5.it/informativa-privacy";
  })(),
  wl_es: "https://life5.es/politica-privacidad-resumida/",
  sql_es: "https://life5.es/politica-privacidad-resumida/",
}

export const TrustpilotLink: GlobalKeyStringI = {
  es: "https://es.trustpilot.com/review/life5.es",
  fr: "https://fr.trustpilot.com/review/life5.fr",
  en: "https://es.trustpilot.com/review/life5.es",
  pt: "https://es.trustpilot.com/review/life5.es",
  it: "https://es.trustpilot.com/review/life5.es",
  wl_es: "",
  sql_es: ""
};

export const urlsLife5: GlobalKeyStringI = {
  es: "https://life5.es",
  en: "https://life5.com",
  fr: "https://life5.fr",
  pt: "",
  it: "",
  wl_es: "https://es.trustpilot.com/review/life5.es",
  sql_es: "https://es.trustpilot.com/review/life5.es"
}

export const GlobalCapitalStepper: GlobalKeyNumberI = {
  es: 10000,
  fr: 10000,
  en: 10000,
  pt: 10000,
  it: 5000,
  wl_es: 5000,
  sql_es: 5000,
}

export const QuotePageBenefitsList: GlobalKeyStringArrayI = {
  it: [
    "aggregator.pricingComparative.immediately",
    "aggregator.pricingComparative.noExamination",
    "aggregator.pricingComparative.online",
    "aggregator.pricingComparative.noPermanence",
  ],
  default: [
    "aggregator.pricingComparative.noPermanence",
    "aggregator.pricingComparative.noExamination",
    "aggregator.pricingComparative.online",
    "aggregator.pricingComparative.immediately",
  ],
};

export const GlobalPromoCodesToApply: GlobalKeyStringOrNullI = {
  es: "3MESES1E",
  fr: "3MOIS1E",
  en: getCookie("gtf_campaign"),
  pt: getCookie("gtf_campaign"),
  it: "3primimesia1euro",
  wl_es: "3MESES1E",
  sql_es: ""
}

export const GlobalYearlyPayments: GlobalInsuranceTypeI = {
  es: true,
  fr: true,
  en: false,
  pt: false,
  it: true,
  wl_es: true,
  sql_es: true,
}

export const GlobalCitizenship: GlobalKeyStringI = {
  es: "ES",
  fr: "FR",
  en: "",
  pt: "",
  it: "IT",
  wl_es: "ES",
  sql_es: "ES",
}

export const GlobalDomainQuestions: GlobalKeyStringI = {
  es: "es",
  fr: "fr",
  en: "en",
  pt: "pt",
  it: "it",
  wl_es: "es",
  sql_es: "es",
}

export const GlobalMinCharactersIdentityNumber: GlobalKeyNumberI = {
  es: 8,
  fr: 0,
  en: 0,
  pt: 0,
  it: 12,
  wl_es: 8,
  sql_es: 8,
}

export const GlobalDocAlice: GlobalKeyStringI = {
  es: "ESP",
  fr: "FRA",
  en: "",
  pt: "",
  it: "ITA",
  wl_es: "ESP",
  sql_es: "ESP",
}

export const GlobalStringsAlice: GlobalKeyStringI = {
  es: "es",
  fr: "fr",
  en: "en",
  pt: "pt",
  it: "it",
  wl_es: "es",
  sql_es: "es",
}

export const GlobalPhoneDashboard: GlobalKeyStringI = {
  "es": "911 984 986",
  "fr": "01 87 21 05 43",
  "it": "02 8294 21 28",
  "en": "",
  "pt": "",
  "wl_es": "911 984 986",
  "sql_es": "911 984 986",
}

export const GlobalDomainAutoCompleteGoogle: GlobalKeyStringI = {
  es: "es",
  fr: "fr",
  en: "en",
  pt: "pt",
  it: "it",
  wl_es: "es",
  sql_es: "es",
}
