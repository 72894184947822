/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router";
import queryString from "query-string";
import FormBuilder from "../../../components/Components/FormBuilder/FormBuilder";
import { handleHubspot } from "../../../utils/HandleHubspot";
import "../css/questions.scss";
import { GetlifeContext } from "../../../context/GetLifeContext.context";
import { I18nContext } from "../../../context/i18n.context";
import HttpLeadRepository from "../../../api/request/Lead/Lead.service";
import FormButton from "../../../components/Components/FormBuilder/FormButtons";
import { FormBuilderProvider } from "../../../context/FormBuilder.context";
import { StatusContext } from "../../../context/StatusContext";
import MaxCapitalModal from "../../../components/MaxCapitalModal/MaxCapitalModal";
import { DOMAIN } from "../../../constants/Global";

const ProductStackQuestions = (props) => {
  const { setIsABtesting } = useContext(GetlifeContext);
  const {
    state: { translate },
  } = useContext(I18nContext);
  const {getStatus} = useContext(StatusContext);

  const [chat, setchat] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const leadRepository = new HttpLeadRepository(props.leadId, props.token);
  const [isDisplayed, setIsDisplayed] = useState(false);
  const navigate = useNavigate();
  const route = Location.pathname;
  const isOneChoinceQuestion =
    props.params.question.type === "radio" &&
    props.params.question.componentName &&
    route === "/stepChallenger/4";

  const handleChat = () => setchat(!chat);
  const handleValues = (e) => {
    let filterValue = [];
    if (
      props.params.question.type === "select_multiple" ||
      props.params.question.type === "checkbox" ||
      props.params.question.type === "checkbox_optional" ||
      props.params.question.type === "checkbox_mandatory"
    ) {
      filterValue = [];
      if (Array.isArray(e)) {
        for (let i = 0; i < e.length; i++) {
          filterValue.push(e[i]);
        }
        if (filterValue.includes("none")) {
          filterValue = filterValue.filter((v) => v !== 'none');
        }
        if (
          filterValue.length === 0 &&
          props.params.question.type === "checkbox_optional"
        ) {
          filterValue.push("none");
        }
      } else {
        filterValue = e;
      }
      props.handleOnChange("data", "answer", filterValue.toString());
    } else {
      const value = e ? e : "";
      props.handleOnChange("data", "answer", value);
    }
  };

  const clearBlockedPopUp = () => {
    props.saveQuestion();
  };

  useEffect(() => {
    handleHubspot();
    const query = queryString.parse(window.location.search);

    if (props.token === false && props.leadId === false) {
      if (Object.keys(query).length === 0) {
        if (
          localStorage.getItem("lead") != undefined &&
          localStorage.getItem("token") != undefined
        ) {
          const lead = localStorage.getItem("lead");
          const token = localStorage.getItem("token");
          props.handleToken(token || "");
          props.handleLeadId(lead);
        } else {
          navigate("/");
        }
      } else {
        props.handleLeadId(query.lead);
      }
    }
  }, []);

  useEffect(() => {
    if (props.token === false && props.leadId !== false) {
      leadRepository.getToken(props.leadId).then((res) => {
        const { token, isAbTest } = res;
        // TODO: SET setIsABtesting from response

        if (token === null) {
          navigate("/");
        } else {
          props.handleToken(token || "");
          setIsABtesting(isAbTest)
        }
      });
    }
  }, [props.leadId]);

  useEffect(() => {
    if (props.token !== false && props.token !== null) {
      getStatus();
      props.refreshNextQuestion();
    }
  }, [props.token]);

  useEffect(() => {
    if (
      selectedValue !== null &&
      props.params.question.type === "radio" &&
      props.params.question.componentName
    ) {
      props.nextQuestion();
      setSelectedValue(null);
    }
  }, [props.callback, selectedValue]);
  return (
    <div className={"questions-outerContainer"}>
      {props.params.question !== null &&
        props.params.question !== undefined &&
        props.isLoading === false && (
          <FormBuilderProvider
            question={props.params.question}
            answer={props.params.answer}
          >
            <div className={"questions-container"}>
              <div className={`questions-innerContainer ${DOMAIN === "it" ? "questions-innerContainer-it" : ""}`}>
                <FormBuilder
                  answer={props.params.answer}
                  chat={chat}
                  handleChange={handleChat}
                  handleGoBack={props.handleOnChange}
                  handleSubmit={props.nextQuestion}
                  handleValues={handleValues}
                  inputWidth={"340px"}
                  isLoading={props.isLoading}
                  question={props.params.question}
                  translate={translate}
                  callback={(e) => {
                    setSelectedValue(e);
                    handleValues(e);
                  }}
                  isOneChoinceQuestionRoute={
                    props.route === "/productQuestionsChallenger"
                  }
                  setIsDisplayed={setIsDisplayed}
                  isDisplayed={isDisplayed}
                />
              </div>
            </div>
            <FormButton
              showButton={true}
              isLoading={props.isLoading}
              isOneChoinceQuestion={isOneChoinceQuestion}
              componentName={props.params.question.componentName}
              translate={translate}
              handleSubmit={props.nextQuestion}
              question={props.params.question}
              answer={props.params.answer}
              goBack={props.goBack}
              questionStack={props.questionStack}
              isSticky={false}
            />
          </FormBuilderProvider>
        )}
      
      <MaxCapitalModal capital={props.params.question.newCapital}/>

      <a target="_blank" id="downloadButton" style={{ display: "none" }}></a>
    </div>
  );
};

export default ProductStackQuestions;
